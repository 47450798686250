import './premium.css';
import Logo from '../../header/images/logo-contalearn.png';
import { Link } from 'react-router-dom';

import ExitOffer from './ExitOffer';

const Finaloffer = () => {
  return (
    <div className='cadru-premium-step-two'>
      <div className='header-prem-top'>
        <div className='header-premium'>
          <div className='logo-dist-premium'>
            <img src={Logo} alt='logo-premium' className='logo-premuim' />
          </div>
          <ExitOffer />
        </div>
      </div>
      <div className='info-cadru-premium'>
        <div className='w-100'>
          <div className='header-premuim-page'>
            <h1>Obtine planul Premium</h1>
          </div>

          <div className='premium-third-page'>
            <div>
            <h4 className='text-center w-80 mt-3'>Plateste acum si primesti 25% reducere</h4>
            <p className='text-center'>Investeste in edicatia ta!</p>
            </div>
            <Link to='/offerdiscount' className='css-link-offer'>
              <button className='offer-button-off'>Obtine 25% reducere</button>
            </Link> 
            <Link to='/startpremiumtrial' className='css-link-offer'>
              <button className='button-try-now'>Incearca gratuit 7 zile</button> 
            </Link>
          </div>

        </div>
       
      </div>
    </div>
  )
}

export default Finaloffer;