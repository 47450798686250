import './premium.css';
import Logo from '../../header/images/logo-contalearn.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'; // Importăm faTimes pentru X
import ExitOffer from './ExitOffer';

const OfferDiscount = () => {
  return (
    <div className='cadru-premium-step-two'>
      <div className='header-prem-top'>
        <div className='header-premium'>
          <div className='logo-dist-premium'>
            <img src={Logo} alt='logo-premium' className='logo-premuim' />
          </div>
          <ExitOffer />
        </div>
      </div>
      <div className='info-cadru-premium'>
        <div className='w-100'>
          <div className='header-premuim-page'>
            <h1>Cumpara acum si primesti 25% discount la planul anual
            </h1>
          </div>
<h4 className='text-center mt-3'>Pret plan anual 180 lei <span style={{textDecoration: 'line-through'}}>{'(240 lei)'}</span></h4>
        <div className='premium-second-page'>
              <div className='mx-3'>
              <div className='d-flex justify-content-between'>
              <div className='mx-3'>
              <div className='item-space-offer'>
                  📘 acces la lectiile de baza
              </div>
              <div className='item-space-offer'>
                  🛠️ acces la lectiile de practica
              </div>
              <div className='item-space-offer'>
                  🎯 acces la teste de final de capitol
              </div>
              <div className='item-space-offer'>
                  🎓 certificat de finalizare a cursului
              </div>

              </div>
            
            </div>
               
              </div>
              <div className='mx-2'>
              <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
              </div>
            </div>

        </div>
        <Link to='/facturare' className='css-link-offer' >
        <button className='button-premium-step-two'>CUMPARA CU DISCOUNT 25%</button>
        </Link>
        
      </div>
    </div>
  )
}

export default OfferDiscount;