import './headerlessons.css';
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faWebAwesome, faHeart, faCoins, faEllipsis, faVolumeHigh, faVolumeMute, faRedo } from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

const HeaderLessons = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const menuRef = useRef(null); // Referință pentru meniul dropdown

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const toggleSound = () => {
    setIsSoundOn(prevState => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Verifică dacă clickul a fost în afara meniului și a iconiței pentru meniu
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <>
      <div className='general-lesson-menu'>
        <div className='header-lesson'>
          <Link to='/cursuri'>
            <FontAwesomeIcon icon={faArrowLeft} className="left-arrow-back-lesson" />
          </Link>
          <div className="icon-with-value">
            <FontAwesomeIcon icon={faHeart} className="left-arrow-back-lesson" />
            <p className="icon-value">20</p> {/* Exemplu de valoare */}
          </div>
          <div className="icon-with-value">
            <FontAwesomeIcon icon={faCoins} className="left-arrow-back-lesson" />
            <p className="icon-value">150</p> {/* Exemplu de valoare */}
          </div>
          <div className='orientation-menu-lesson' ref={menuRef}>
            <div onClick={toggleMenu}>
              <FontAwesomeIcon icon={faEllipsis} className="menu-lessons-right" />
            </div>
            {isMenuOpen && (
              <div className='submenu-lesson'>
                <div className='elements-lesson'>
                  <div onClick={toggleSound} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={isSoundOn ? faVolumeHigh : faVolumeMute} />
                    <span className='mx-2 custom-sound'>{isSoundOn ? ' Off' : ' On'}</span>
                  </div>
                  <p>
                    <Link to='/userpage'>
                      <FontAwesomeIcon icon={faUserRegular} />  <span className='mx-2 fw-bold'>Profilul meu</span>
                    </Link>
                  </p>
                  <p>
                  <Link to='/resetareprogres'>
                    <FontAwesomeIcon icon={faRedo} /> <span className='mx-2 fw-bold'>Resetare progres</span>
                    </Link>
                  </p>
                  <p>
                  <Link to='/premium'>
                    <FontAwesomeIcon icon={faWebAwesome} /> <span className='mx-2 fw-bold'>Premium</span>
                  </Link>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderLessons;
