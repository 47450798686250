import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Utilizăm js-cookie pentru a gestiona cookie-urile
import { Link } from 'react-router-dom';
import './cookie.css';
const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Verificăm dacă utilizatorul a acceptat deja cookie-urile
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      // Dacă nu a acceptat cookie-urile, afișăm banner-ul
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    // Setăm un cookie când utilizatorul acceptă politica de cookie-uri
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setShowBanner(false); // Ascundem banner-ul
  };

  if (!showBanner) {
    return null; // Nu afișăm nimic dacă utilizatorul a acceptat deja cookie-urile
  }

  return (
    <div className="cookie-consent-banner" style={{ padding: '1em', background: '#f4f4f4', position: 'fixed', bottom: 0, width: '100%', textAlign: 'center' }}>
      <p>
        Acest site folosește cookie-uri pentru a îmbunătăți experiența de navigare. Pentru mai multe detalii, consultă 
        <Link to='/politicacookies'> Politica de cookie-uri</Link>
      </p>
      <button onClick={handleAccept} style={{ padding: '1 em 2.5em', background: '#16c79e', color: '#fff', border: 'none', cursor: 'pointer', borderRadius: '10px', fontSize: '1.2em' }}>
        Accept
      </button>
    </div>
  );
};

export default CookieConsent;
