import './premium.css';
import Logo from '../../header/images/logo-contalearn.png';


import ExitOffer from './ExitOffer';

const Viewplans = () => {
  return (
    <div className='cadru-premium-step-two'>
      <div className='header-prem-top'>
        <div className='header-premium'>
          <div className='logo-dist-premium'>
            <img src={Logo} alt='logo-premium' className='logo-premuim' />
          </div>
          <ExitOffer />
        </div>
      </div>
      <div className='info-cadru-premium'>
        <div className='w-100'>
          <div className='header-premuim-page'>
            <h1>Obtine planul Premium</h1>
          </div>

          <div className='premium-third-page'>
   

    {/* Plan Semestrial */}
    <p className='fw-bold m-auto'>Semestrial</p>
    <label className="plan-label-price">
        
        <div className='plan-price-price-plan'>
        <input type="radio" name="plan-option" className="plan-radio" />
            <div className='align-plans-elements'>
                <div className='details-price-price'>
                    <p className='fw-bold m-auto'><span className='growth-text-price-plans'>180</span> lei/6 luni</p>
                </div>
                <div className='line-price'></div>
                <div className='details-price-price'>
                    <p className='fw-bold m-auto'><span className='growth-text-price-plans'>30</span> lei/luna</p>
                </div>
            </div>
        </div>
    </label>


     {/* Plan Anual */}
     <p className='fw-bold m-auto fs-4'>Anual</p>
    <label className="plan-label-price-best">
        
        <div className='plan-price-price-plan-best'>
           
            <input type="radio" name="plan-option" className="plan-radio" />
            <div className='align-plans-elements'>
                <div className='details-price-price'>
                    <p className='fw-bold m-auto'><span className='growth-text-price-plans-best'>240</span>  lei/an</p>
                </div>
                <div className='line-price-best'></div>
                <div className='details-price-price'>
                    <p className='fw-bold m-auto'><span className='growth-text-price-plans-best'>20</span> lei/luna</p>
                </div>
            </div>
        </div>
    </label>


    {/* Plan Lunar */}
    <p className='fw-bold m-auto'>Lunar</p>
    <label className="plan-label-price">
        <div className='plan-price-price-plan'>
            <input type="radio" name="plan-option" className="plan-radio" />
            <div className='align-plans-elements'>
                <div className='details-price-price'>
                    <p className='fw-bold m-auto'><span className='growth-text-price-plans'>35</span> lei/luna</p>
                </div>
            </div>
        </div>
    </label>

    <button className='btn btn-warning m-auto fw-bold px-3'>Mergi catre plata!</button>
</div>



        </div>
        
      </div>
    </div>
  )
}

export default Viewplans;