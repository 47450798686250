import './premium.css';
import Logo from '../../header/images/logo-contalearn.png';
import { Link } from 'react-router-dom';

import ExitOffer from './ExitOffer';

const NextStepOffer = () => {
  return (
    <div className='cadru-premium-step-two'>
      <div className='header-prem-top'>
        <div className='header-premium'>
          <div className='logo-dist-premium'>
            <img src={Logo} alt='logo-premium' className='logo-premuim' />
          </div>
          <ExitOffer />
        </div>
      </div>
      <div className='info-cadru-premium'>
        <div className='w-100'>
          <div className='header-premuim-page'>
            <h1>Obtine planul Premium</h1>
          </div>

          <div className='premium-third-page'>
            <div className='size-text-offer'>
                


            </div>
            <div> 
                <h2 className='text-center w-80 mt-3'>Oferta cea mai avantajoasă pentru tine!</h2>
                <div className='plan-price-one'>
                <div>
                    <b>Anual</b>
                </div>
                <div className='plan-price'>
                    <div className='details-price'>
                        <p className='text-center fw-bold'><span className='growth-text-price'>240</span> lei/an</p>
                        
                    </div>
                    <div className='line-price'></div>
                    <div className='details-price'>
                      <p className='text-center fw-bold'><span className='growth-text-price'>20</span> lei/luna</p>
                      </div>
                    </div>
                    <div>
                    <p>Facturare anuala dupa perioada free</p>
                    </div>
                </div>
                
            </div>  
          </div>
          
        </div>
        <Link to='/finaloffer'>
          <button className='button-premium-step-two'>Incearca 7 zile gratuit</button>
        </Link>
        <Link to='/viewplans'>
          <button className='button-premium-see-plans'>Vezi toate planurile</button>
        </Link>
        
      </div>
    </div>
  )
}

export default NextStepOffer;