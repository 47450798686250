

const ResetareProgres = () => {
  return (
    <div>
      
        <p>Reseteaza progresul lectiei!</p>
    </div>
  )
}

export default ResetareProgres;